// src/components/ProductCard.js

import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <Link to={`/product/${product.id}`}>
        <img src={product.imageURL} alt={product.name} className="product-image" />
        <h4>{product.name}</h4>
        <p>Price: ₹{product.price}</p>
      </Link>
    </div>
  );
};

export default ProductCard;
