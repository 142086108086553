import React from 'react';
import '../styles/makeup.css'; // Ensure to include the CSS file

const makeupServices = [
  {
    name: "Basic Makeup",
    description: "Natural look makeup perfect for any occasion.",
  },
  {
    name: "Party Makeup",
    description: "Glowing and glamorous makeup for parties and events.",
  },
  {
    name: "Bridal Makeup",
    description: "Comprehensive bridal makeup package for your special day.",
  },
  {
    name: "Bridal HD & 3D",
    description: "Comprehensive smooth and natural bridal makeup package for your special day.",
  },
  {
    name: "Corporate Looks",
    description: "Style of makeup that's worn in a professional setting to enhance natural beauty.",
  },
  {
    name: "Nude Looks",
    description: "Natural shade that match your skin, no makeup look.",
  },
  {
    name: "Sider Makeup",
    description: "A glomorus makeup style for bridesmaids.",
  },
  {
    name: "Fashion Makeup",
    description: "Design to be impactful and enhance of a model appearence for fashion.",
  },
  {
    name: "Monocromatic",
    description: "Look uses different shade of single color.",
  },
];

const MakeUp = () => {
  return (
    <div className="makeup-container">
      <h1 className="makeup-title">Makeup Services</h1>
      <p className="makeup-intro">
        Explore our wide range of makeup services tailored to enhance your beauty. 
        Whether it's a special occasion or everyday glam, we have the right service for you.
      </p>
      <div className="makeup-services">
        {makeupServices.map((service, idx) => (
          <div key={idx} className="makeup-service-card">
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MakeUp;
