import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../config/Firebase';
import Spinner from '../components/Spinner';
import StarRating from "../components/StarRating";
import ProductRecommendations from "../components/ProductRecommendations";

import '../styles/productDetails.css';

const ProductDetails = () => { 
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showComments, setShowComments] = useState(false);


  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = await getDoc(doc(db, "products", id));
        if (productDoc.exists()) {
          setProduct(productDoc.data());
        } else {
          console.error("No such product!");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  // Show loading spinner if data is being fetched
  if (loading) {
    return <Spinner />;
  }

 

  

  return (
    <div className="product-details">
      {product ? (
        <div className="product-detail-container">
          <h2>{product.name}</h2>
          
          <img src={product.imageURL} alt={product.name} className="product-image" />

          <p className="product-description">{product.description}</p>


          {/* Rating Section */}
          <div className="rating-section">
            <StarRating rating={product.rating || 0} />
          </div>

          {/* Comments Section */}
          <div className="comments-section">
            <h4 onClick={() => setShowComments(!showComments)} style={{ cursor: 'pointer' }}>
              Comments ({product.comments ? product.comments.length : 0})
            </h4>
            {showComments && (
              <div className="comments-dropdown">
                {product.comments && product.comments.length > 0 ? (
                  product.comments.map((comment, index) => (
                    <div key={index}>
                      <p>{comment.comment}</p>
                    </div>
                  ))
                ) : (
                  <p>No comments yet.</p>
                )}
              </div>
            )}
          </div>

          {/* Buttons for Add to Cart, Buy Now, and Call Now */}
          <div className="buttons-container">

          </div>

     
          <ProductRecommendations category={product.category} />
        </div>
      ) : (
        <p>Product not found!</p>
      )}
    </div>
  );
};

export default ProductDetails;
