import React from 'react';
import '../styles/nailservice.css'; // Ensure to include the CSS file

const nailServices = [
  {
    name: "Basic Manicure",
    description: "A simple nail care service including cuticle care and polish.",
  },
  {
    name: "Gel Manicure",
    description: "Long-lasting gel polish applied with a meticulous manicure.",
  },
  {
    name: "Nail Art",
    description: "Creative and artistic designs applied to your nails.",
  },
  {
    name: "Acrylic Nails",
    description: "Durable acrylic nails for an enhanced look.",
  },
  {
    name: "Nail Repair",
    description: "Quick and professional repair of broken nails.",
  },
  {
    name: "Gel-X extension",
    description: "A type of nail extension made from gel that can be aplied to natural look to make them longer.",
  },
  {
    name: "Press-On-Nail",
    description: "Ready to stick nail on naturak nails.",
  },
  {
    name: "Removal",
    description: "Process of removing artificial nails from natural nails.",
  },
  {
    name: "Refilling",
    description: "Adding new material to the part of nail extension that has grown out.",
  },
];

const NailService = () => {
  return (
    <div className="nailservice-container">
      <h1 className="nailservice-title">Nail Services</h1>
      <p className="nailservice-intro">
        Indulge in our nail services that combine care and creativity. 
        Whether you're looking for a classic manicure or unique nail art, we have the perfect service for you.
      </p>
      <div className="nailservice-services">
        {nailServices.map((service, idx) => (
          <div key={idx} className="nailservice-service-card">
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NailService;
