import React, { useState } from "react";
import { db } from "../config/Firebase";
import { addDoc, collection, serverTimestamp, getDoc } from "firebase/firestore";


const AddProduct = ({ setProducts }) => {
  const [newProduct, setNewProduct] = useState({
    name: "",
    imageURL: "",
    description: "",
    category: [],
  });
  const [loading, setLoading] = useState(false); // To handle loading state
  const [errorMessage, setErrorMessage] = useState(""); // To handle errors

  const categoryOptions = [
    "Facial",
    "HairService",
    "MakeUp",
    "MeniPedi",
    "NailService",
    "Threading",
    "Waxing",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const options = Array.from(e.target.selectedOptions, (option) => option.value);
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      category: options,
    }));
  };

  const addProduct = async (e) => {
    e.preventDefault();

    // Validate that at least one category is selected
    if (newProduct.category.length === 0) {
      setErrorMessage("Please select at least one category.");
      return;
    }

    setLoading(true);
    setErrorMessage(""); // Reset error message before submission

    try {
      const productsCollectionRef = collection(db, "products");
      const docRef = await addDoc(productsCollectionRef, {
        ...newProduct,
        createdAt: serverTimestamp(),
      });

      // Fetch the newly added product to ensure proper data
      const newProductSnapshot = await getDoc(docRef);
      const newProductData = {
        id: newProductSnapshot.id,
        ...newProductSnapshot.data(),
      };

      // Update the parent component's state
      setProducts((prevProducts) => [
        ...prevProducts,
        newProductData, // Add fetched product with Firestore timestamp
      ]);

      // Reset the form after submission
      setNewProduct({
        name: "",
        imageURL: "",
        description: "",
        category: [],
      });
      alert("Product added successfully!");
    } catch (error) {
      console.error("Error adding product:", error);
      setErrorMessage("Error adding product. Please try again.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <form onSubmit={addProduct} className="product-form">
      <input
        type="text"
        name="name"
        placeholder="Product Name"
        value={newProduct.name}
        onChange={handleInputChange}
        required
        className="form-input"
      />

      <input
        type="text"
        name="imageURL"
        placeholder="Image URL"
        value={newProduct.imageURL}
        onChange={handleInputChange}
        required
        className="form-input"
      />

      <textarea
        name="description"
        placeholder="Description"
        value={newProduct.description}
        onChange={handleInputChange}
        required
        className="form-textarea"
      ></textarea>

      <select
        multiple
        name="category"
        value={newProduct.category}
        onChange={handleCategoryChange}
        required
        className="form-input"
      >
        {categoryOptions.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <button type="submit" className="form-button" disabled={loading}>
        {loading ? "Adding..." : "Add Product"}
      </button>
    </form>
  );
};

export default AddProduct;
