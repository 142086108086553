import React, { useState, useEffect } from 'react';
import { firestore, collection, addDoc, getDocs } from '../config/Firebase';
import { FaStar, FaRegStar } from 'react-icons/fa'; // Import the icons

const Rating = () => {
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [averageRating, setAverageRating] = useState(0);

  // Function to fetch average rating
  const fetchAverageRating = async () => {
    try {
      const snapshot = await getDocs(collection(firestore, 'ratings'));
      const totalRatings = snapshot.docs.length;

      if (totalRatings > 0) {
        const sum = snapshot.docs.reduce((acc, doc) => acc + doc.data().rating, 0);
        const average = (sum / totalRatings).toFixed(1); // Calculate average
        setAverageRating(average); // Set average rating state
      } else {
        setAverageRating(0); // Reset average rating if no ratings
      }
    } catch (error) {
      console.error('Error fetching average rating:', error);
    }
  };

  // Fetch average rating on component mount
  useEffect(() => {
    fetchAverageRating(); // Call the fetch function
  }, []); // Empty dependency array to run only on mount

  const handleSubmit = async () => {
    if (rating < 1 || rating > 5) {
      alert('Please select a rating between 1 and 5.');
      return; // Prevent submission if rating is out of bounds
    }

    try {
      await addDoc(collection(firestore, 'ratings'), {
        rating,
        timestamp: new Date(), // Store current timestamp
      });
      setRating(0); // Reset rating after submission
      setSubmitted(true);
      fetchAverageRating(); // Call this function to refresh average rating
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  return (
    <div className="p-5">
      <h2 className="text-xl font-bold">Rate Us</h2>
      <div className="flex mt-2">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`cursor-pointer ${star <= rating ? 'text-green-500' : 'text-gray-500'}`}
            onClick={() => setRating(star)} // Update rating when star is clicked
          >
            {star <= rating ? <FaStar /> : <FaRegStar />} {/* Render filled or empty star */}
          </span>
        ))}
      </div>
      <button
        className="bg-green-500 text-white p-2 mt-2"
        onClick={handleSubmit}
        disabled={submitted}
      >
        {submitted ? 'Rating Submitted' : 'Submit Rating'}
      </button>
      {averageRating > 0 && <p className="mt-2">Average Rating: {averageRating}</p>} {/* Display average rating */}
    </div>
  );
};

export default Rating;
