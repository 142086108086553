import React from 'react';
import '../styles/facial.css'; // Ensure to include the CSS file

const facialServices = [
  {
    name: "Hydrating Facial",
    description: "A soothing treatment to hydrate and nourish dry skin.",
    price: "$75"
  },
  {
    name: "Anti-Aging Facial",
    description: "Targets fine lines and wrinkles to promote youthful skin.",
    price: "$90"
  },
  {
    name: "Acne Treatment Facial",
    description: "Designed to treat and prevent acne breakouts.",
    price: "$85"
  },
  {
    name: "Brightening Facial",
    description: "Helps to lighten dark spots and even out skin tone.",
    price: "$80"
  },
  {
    name: "Relaxing Facial",
    description: "A calming experience to relax the mind and rejuvenate the skin.",
    price: "$70"
  },
  {
    name: "Exfoliating Facial",
    description: "Removes dead skin cells for a smoother complexion.",
    price: "$75"
  },
  {
    name: "Firming Facial",
    description: "Tightens and firms the skin for a lifted appearance.",
    price: "$95"
  },
];

const Facial = () => {
  return (
    <div className="facial-container">
      <h1 className="facial-title">Facial Services</h1>
      <p className="facial-intro">
        Discover our range of facial services designed to rejuvenate and pamper your skin. 
        Each treatment is tailored to meet your unique skin needs.
      </p>
      <div className="facial-services">
        {facialServices.map((service, idx) => (
          <div key={idx} className="facial-service-card">
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facial;
