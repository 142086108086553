// AdminDashboard.js
import React, { useState, useEffect } from "react";
import { db } from "../config/Firebase";
import { collection, getDocs, doc, deleteDoc, updateDoc } from "firebase/firestore";
import AddProduct from "../components/AddProduct";
import "../styles/adminDashboard.css";

const AdminDashboard = () => {
  const [products, setProducts] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState({
    name: "",
    imageURL: "",
    description: "",
    category: [],
  });
  const [searchTerm, setSearchTerm] = useState("");

  const productsCollectionRef = collection(db, "products");

  // Fetch products on component mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getDocs(productsCollectionRef);
        setProducts(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  // Delete a product
  const deleteProduct = async (productId) => {
    try {
      const productDoc = doc(db, "products", productId);
      await deleteDoc(productDoc);
      alert("Product deleted successfully!");
      const data = await getDocs(productsCollectionRef);
      setProducts(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error deleting product: ", error);
    }
  };

  // Start editing a product
  const startEditing = (product) => {
    setEditingProductId(product.id);
    setUpdatedProduct({
      name: product.name,
      imageURL: product.imageURL,
      description: product.description,
      category: product.category || [],
    });
  };

  // Update a product
  const updateProduct = async (e) => {
    e.preventDefault();
    try {
      const productDoc = doc(db, "products", editingProductId);
      await updateDoc(productDoc, updatedProduct);
      alert("Product updated successfully!");

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === editingProductId
            ? { ...product, ...updatedProduct }
            : product
        )
      );
      setEditingProductId(null);
    } catch (error) {
      console.error("Error updating product: ", error);
      alert("Error updating product: " + error.message);
    }
  };

  // Filter products by search term
  const filteredProducts = products.filter((product) => {
    const productName = product.name ? product.name.toLowerCase() : "";
    const searchQuery = searchTerm ? searchTerm.toLowerCase() : "";
    if (!searchQuery) {
      return true;
    }
    return productName.includes(searchQuery);
  });

  return (
    <div className="admin-dashboard">
      <h2>Admin Panel</h2>

      <AddProduct setProducts={setProducts} /> {/* Render AddProduct component */}

      <h2>Product List, Search the product by its name</h2>
      <br />

      <input
        type="text"
        placeholder="Search by name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      <div className="product-list">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <div key={product.id} className="product-item">
              {editingProductId === product.id ? (
                <form onSubmit={updateProduct} className="product-update-form">
                  <input
                    type="text"
                    value={updatedProduct.name}
                    onChange={(e) =>
                      setUpdatedProduct({
                        ...updatedProduct,
                        name: e.target.value,
                      })
                    }
                    required
                    className="form-input"
                  />
                  <input
                    type="text"
                    value={updatedProduct.description}
                    onChange={(e) =>
                      setUpdatedProduct({
                        ...updatedProduct,
                        name: e.target.value,
                      })
                    }
                    required
                    className="form-input"
                  />
                  <input
                    type="text"
                    value={updatedProduct.imageURL}
                    onChange={(e) =>
                      setUpdatedProduct({
                        ...updatedProduct,
                        name: e.target.value,
                      })
                    }
                    required
                    className="form-input"
                  />
                  <input
                    type="text"
                    value={updatedProduct.category}
                    onChange={(e) =>
                      setUpdatedProduct({
                        ...updatedProduct,
                        name: e.target.value,
                      })
                    }
                    required
                    className="form-input"
                  />
                  {/* Add more inputs for updating the product */}
                  <button type="submit" className="form-button">
                    Update Product
                  </button>
                  <button
                    type="button"
                    onClick={() => setEditingProductId(null)}
                    className="form-button cancel-button"
                  >
                    Cancel
                  </button>
                </form>
              ) : (
                <div className="product-box">
                  <h3>{product.name}</h3>
                  <img src={product.imageURL} alt={product.name} />
                  <p>Created At: {product.createdAt?.toDate().toLocaleDateString() || "N/A"}</p>

                  <div className="product-actions">
                    <button onClick={() => deleteProduct(product.id)} className="action-button">
                      Delete
                    </button>
                    <button onClick={() => startEditing(product)} className="action-button">
                      Update
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No products available.</p>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
