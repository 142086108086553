import React, { useState, useEffect } from "react";
import { db } from '../config/Firebase';
import { collection, getDocs, query, where } from "firebase/firestore";
import Spinner from './Spinner';
import ProductCard from "./ProductCart";

const ProductRecommendations = ({ category }) => {
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecommendedProducts = async () => {
      try {
        const q = query(collection(db, "products"), where("category", "==", category));
        const querySnapshot = await getDocs(q);
        const products = [];
        querySnapshot.forEach((doc) => {
          products.push({ id: doc.id, ...doc.data() });
        });
        setRecommendedProducts(products);
      } catch (error) {
        console.error("Error fetching recommended products:", error);
      } finally {
        setLoading(false);
      }
    };

    if (category) {
      fetchRecommendedProducts();
    }
  }, [category]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="product-recommendations">
      <h3>Recommended Products</h3>
      <div className="recommendation-list">
        {recommendedProducts.length > 0 ? (
          recommendedProducts.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))
        ) : (
          <p>No recommendations available.</p>
        )}
      </div>
    </div>
  );
};

export default ProductRecommendations;
