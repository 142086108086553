import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../config/Firebase'; // Ensure Firebase is configured correctly
import { signOut, onAuthStateChanged } from 'firebase/auth';
import '../styles/navbar.css'; // Import custom CSS for Navbar

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // For controlling the mobile menu

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Set current authenticated user
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null); // Clear user state after sign out
    } catch (error) {
      console.error('Sign out error', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle mobile menu visibility
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Brand Logo */}
        <Link to="/" className="navbar-brand">
          SAAJ MAKEUP STUDIO 
        </Link>

        {/* Hamburger Icon for Mobile */}
        <button className="navbar-toggle" onClick={toggleMenu}>
          <svg className="toggle-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            {isMenuOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            )}
          </svg>
        </button>

        {/* Links for larger screens */}
        <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <Link to="/" className="navbar-link">Home</Link>
          <Link to="/all-collection" className="navbar-link">Collection</Link>

          {/* Show "Admin Dashboard" if logged in as the admin */}
          {user?.email === 'test@gmail.com' && (
            <Link to="/admin-dashboard" className="navbar-link">Admin Dashboard</Link>
          )}

          {/* Show "Admin" login link if the user is not logged in */}
          {!user && (
            <Link to="/admin-login" className="navbar-link">Admin</Link>
          )}

          {/* Sign Out button for logged-in users */}
          {user && (
            <button onClick={handleSignOut} className="navbar-link">Sign Out</button>
          )}

<Link to="/about" className="navbar-link">About</Link>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="mobile-menu">
          <Link to="/" className="mobile-menu-link">Home</Link>
          <Link to="/all-collection" className="mobile-menu-link">Collection</Link>

          {/* Mobile version of admin dashboard link */}
          {user?.email === 'test@gmail.com' && (
            <Link to="/admin-dashboard" className="mobile-menu-link">Admin Dashboard</Link>
          )}

          {/* Show "Admin" login link if the user is not logged in */}
          {!user && (
            <Link to="/admin-login" className="mobile-menu-link">Admin</Link>
          )}

          {/* Sign Out button for logged-in users */}
          {user && (
            <button onClick={handleSignOut} className="mobile-menu-link">Sign Out</button>
          )}
          <Link to="/about" className="navbar-link">About</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
