import React from 'react';
import '../styles/footer.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        {/* About Section */}
        <div className="footer-section about">
          <h2>Quick Links</h2>
          <ul className="footer-links">
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/contact-us">Contact Us</Link></li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="footer-section contact">
          <h2>Address</h2>
          <p><i className="fa fa-map-marker"></i> 4/95, B.M.C. Building, S.S Road, Near Carnac Bridge, Mumbai- 400001</p>
          <p><i className="fa fa-phone"></i> +91 9987574129</p>
          <p><i className="fa fa-envelope"></i> contact@saajmakeup.com</p>
        </div>
        
        {/* Developer Section */}
        <div className="footer-section links">
          <h2>Application Developer</h2>
          <a href="https://www.facebook.com/profile.php?id=100091608874277" target="_blank" rel="noopener noreferrer">
            <img src="https://avatars.githubusercontent.com/u/84491720?v=4" alt="Aqueeq Azam's Facebook" />
          </a>
          <p>Aqueeq Azam</p>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; 2024 Saaj Makeup Studio | Designed by Saaj Studios</p>
      </div>
    </footer>
  );
};

export default Footer;
