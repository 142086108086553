import React from "react";
import PropTypes from "prop-types";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const StarRating = ({ rating, onRatingChange }) => {
  const roundedRating = Math.round(rating);
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= roundedRating) {
      stars.push(
        <FaStar
          key={i}
          style={{ color: "#FFD700", cursor: "pointer" }}
          onClick={() => onRatingChange(i)} // Handle star click
        />
      );
    } else if (i === roundedRating + 1 && rating % 1 !== 0) {
      stars.push(
        <FaStarHalfAlt
          key={i}
          style={{ color: "#FFD700", cursor: "pointer" }}
          onClick={() => onRatingChange(i)} // Handle star click
        />
      );
    } else {
      stars.push(
        <FaRegStar
          key={i}
          style={{ color: "#FFD700", cursor: "pointer" }}
          onClick={() => onRatingChange(i)} // Handle star click
        />
      );
    }
  }

  return <div className="star-rating">{stars}</div>;
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  onRatingChange: PropTypes.func.isRequired, // Ensure that onRatingChange is a required function
};

export default StarRating;
