import React from 'react';
import '../styles/about.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>Welcome to SAAJ MAKEUP STUDIO</h1>
      <p>
        At SAAJ MAKEUP STUDIO, we believe that beauty is an expression of individuality, and our goal is to make every client look and feel their best. Our makeup artists are experts in their field, offering personalized services that enhance your natural beauty while bringing out your inner confidence.
      </p>

      <p>
        Whether you're looking for a glamorous makeover, a bridal look, or a subtle everyday style, SAAJ Makeup Studio has you covered. We specialize in all aspects of beauty, from makeup and hairstyling to skin treatments, and use only the highest-quality products to ensure stunning, long-lasting results.
      </p>

      <div className="about-mission">
        <h2>Our Mission</h2>
        <p>
          Our mission at SAAJ MAKEUP STUDIO is to provide every client with a tailored beauty experience that makes them feel empowered and beautiful. We strive to create looks that match your unique style and personality, while always prioritizing the health of your skin and hair.
        </p>
      </div>

      <div className="about-values">
        <h2>Our Core Values</h2>
        <ul>
          <li><strong>Excellence:</strong> We deliver the highest standard of service in every session.</li>
          <li><strong>Creativity:</strong> Our artists bring innovation and creativity to every look.</li>
          <li><strong>Client-Centered:</strong> We value our clients’ preferences and take a personalized approach.</li>
          <li><strong>Passion:</strong> We are passionate about enhancing beauty and helping people feel amazing.</li>
          <li><strong>Inclusivity:</strong> We celebrate diversity and believe in making everyone feel beautiful, no matter their skin tone or style preference.</li>
        </ul>
      </div>

      <div className="about-team">
        <h2>Meet Our Team</h2>
        <p>
          Our talented team of makeup artists and beauty specialists has years of experience in the industry. From bridal makeup to editorial shoots, our experts have worked on a variety of clients and events, always providing impeccable service and breathtaking results.
        </p>
      </div>

      <div className="about-cta">
        <h2>Book Your Appointment</h2>
        <p>
          Ready to transform your look? Book your appointment at SAAJ MAKEUP STUDIO today and experience the magic of personalized beauty treatments in a relaxing, luxurious environment.
        </p>
      </div>
    </div>
  );
};

export default About;
