import React, { useState, useEffect } from 'react';
import { firestore, collection, addDoc, getDocs } from '../config/Firebase';
import '../styles/feedback.css'; // Import external CSS for styling

const Feedback = () => {
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [username, setUsername] = useState('');
  const [feedbackList, setFeedbackList] = useState([]); // State to hold feedback entries

  // Fetch feedback from Firestore on component mount
  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const snapshot = await getDocs(collection(firestore, 'feedback'));
        const feedbackData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFeedbackList(feedbackData); // Set the fetched feedback to state
      } catch (error) {
        console.error('Error fetching feedback:', error);
      }
    };

    fetchFeedback(); // Call the fetch function
  }, []); // Empty dependency array to run only on mount

  const handleSubmit = async () => {
    // Check for non-empty fields
    if (!username || !feedback) {
      alert('Please enter your username and feedback.');
      return; // Prevent submission if any field is empty
    }

    try {
      await addDoc(collection(firestore, 'feedback'), {
        username, // Use the provided username
        text: feedback,
      });
      setFeedback(''); // Clear feedback after submission
      setUsername(''); // Clear username after submission
      setSubmitted(true);

      // Update feedback list locally
      setFeedbackList(prev => [...prev, { username, text: feedback }]);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="feedback-container">

      <input
        type="text"
        placeholder="Your name"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="input-field"
        required
      />
      <textarea
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="Your feedback here..."
        className="textarea-field"
        required
      />
      <button
        className="submit-btn"
        onClick={handleSubmit}
        disabled={submitted}
      >
        {submitted ? 'Feedback Submitted' : 'Submit Feedback'}
      </button>

      {/* Display all feedback in a single dropdown */}
      <div className="feedback-list">
        <details>
          <summary><strong>Submitted Feedback</strong></summary>
          <ul>
            {feedbackList.map((item) => (
              <li key={item.id}>
                <strong>{item.username}:</strong> {item.text}
              </li>
            ))}
          </ul>
        </details>
      </div>
    </div>
  );
};

export default Feedback;
