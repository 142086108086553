import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/Firebase"; // Assuming you have Firebase initialized here
import { useNavigate } from "react-router-dom";
import StarRating from "../components/StarRating";
import '../styles/allProducts.css';
import Rating from "../components/Rating";
import Feedback from "../components/Feedback";

const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleCount, setVisibleCount] = useState(5); // Count of visible products
  const [searchTerm, setSearchTerm] = useState(""); // Search input
  const [selectedCategories, setSelectedCategories] = useState([]); // Filtered categories
  const [loadingMore, setLoadingMore] = useState(false); // Loading state for the "Load More" button

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecentProducts = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort products by 'createdAt' date in descending order (most recent first)
        const sortedProducts = productsArray.sort((a, b) => {
          const dateA = a.createdAt ? a.createdAt.seconds : 0;
          const dateB = b.createdAt ? b.createdAt.seconds : 0;
          return dateB - dateA; 
        });

        setProducts(sortedProducts);
      } catch (error) {
        console.error("Error fetching recent products:", error);
        setError("Failed to fetch recent products.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecentProducts();
  }, []);

  const handleViewDetails = (productId) => {
    navigate(`/collection-details/${productId}`);
  };

  const loadMore = () => {
    setLoadingMore(true); // Set loading state for loading more products
    setVisibleCount((prevCount) => prevCount + 5); // Increase the count of visible products
    setLoadingMore(false); // Reset loading state after loading more products
  };

  // Search and Filter Logic
  const filteredProducts = products.filter((product) => {
    const matchesSearchTerm = product.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategories.length === 0 || selectedCategories.includes(product.category);
   
    return matchesSearchTerm && matchesCategory;
  });

  return (
    <div className="most-recent-products">
      <h2>Most Recent Products</h2>

      <div className="filter-container">
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        
        <select
          multiple
          value={selectedCategories}
          onChange={(e) => {
            const options = Array.from(e.target.selectedOptions).map(option => option.value);
            setSelectedCategories(options);
          }}
          className="filter-input"
        >
          <option value="">Select Categories</option>
          <option value="Electronics">Facial</option>
          <option value="Clothing">MakeUp</option>
          <option value="Home & Kitchen">HairService</option>
          <option value="Sports">MeniPedi</option>
          <option value="Books">NailService</option>
          <option value="Beauty">Threading</option>
          <option value="Footwear">Waxing</option>
        </select>

        
      </div>

      {loading && <div>Loading recent products...</div>}
      {error && <div>{error}</div>}
      
      <div className="products-grid">
        {filteredProducts.length === 0 ? (
          <div>No recent products found.</div>
        ) : (
          filteredProducts.slice(0, visibleCount).map((product) => (
            <div key={product.id} className="product-item">
              <img
                onClick={() => handleViewDetails(product.id)}
                style={{ cursor: "pointer" }}
                src={product.imageURL}
                alt={product.name}
              />
              <h3>{product.name}</h3>
              <Rating rating={product.rating || 0} />
              <Feedback/>
            </div>
          ))
        )}
      </div>

      {/* Load More Button */}
      {filteredProducts.length > visibleCount && (
        <button onClick={loadMore} className="load-more-button" disabled={loadingMore}>
          {loadingMore ? "Loading..." : "Load More"}
        </button>
      )}
    </div>
  );
};

export default AllProducts;
