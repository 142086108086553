import React from 'react';
import '../styles/privacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>
        At SAAJ MAKEUP STUDIO, your privacy is very important to us. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our services or visit our website.
      </p>

      <div className="privacy-section">
        <h2>Information We Collect</h2>
        <p>
          We may collect personal information such as your name, email address, phone number, and payment details when you book an appointment or subscribe to our newsletter. Additionally, we collect information about your interactions with our website through cookies and analytics tools.
        </p>
      </div>

      <div className="privacy-section">
        <h2>How We Use Your Information</h2>
        <p>
          The information we collect is used to:
        </p>
        <ul>
          <li>Process appointments and service requests</li>
          <li>Send promotional emails and newsletters (if you opt-in)</li>
          <li>Improve our website and services based on user interactions</li>
          <li>Comply with legal obligations</li>
        </ul>
      </div>

      <div className="privacy-section">
        <h2>Information Sharing</h2>
        <p>
          We do not sell or share your personal information with third parties, except for service providers who assist us in running our business (e.g., payment processors or email marketing services).
        </p>
      </div>

      <div className="privacy-section">
        <h2>Data Security</h2>
        <p>
          We take reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. However, please note that no online transmission is completely secure.
        </p>
      </div>

      <div className="privacy-section">
        <h2>Changes to This Policy</h2>
        <p>
          We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new policy on our website.
        </p>
      </div>

      <div className="privacy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about our Privacy Policy, please contact us at privacy@saajmakeupstudio.com.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
