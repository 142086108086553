import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.css'; // Ensure your CSS file is created and styles are applied
import Facial from "../images/facial.jfif"; // Ensure image paths are correct
import Threading from '../images/threading.jfif';
import MeniPedi from '../images/meni-pedi.jfif';
import Waxing from '../images/waxing.jfif';
import HairService from '../images/hair-service.jfif';
import Makeup from '../images/makeup.jfif';
import NailService from '../images/nail-services.jfif';
import Lalita1 from '../images/lalita1.jpg';
import Lalita2 from '../images/lalita2.jpg';
import Lalita3 from '../images/lalita3.jpg';
import Lalita4 from '../images/lalita4.jpg';
import Lalita5 from '../images/lalita5.jfif';
import Lalita6 from '../images/lalita6.jpg';
import Lalita7 from '../images/lalita7.jpg';
import Lalita8 from '../images/lalita8.jpg';
import Lalita9 from '../images/lalita9.jpg';
import Lalita10 from '../images/lalita10.jpg';
import Lalita11 from '../images/lalita11.jpg';
import Lalita12 from '../images/lalita12.jpg';
import Footer from './Footer';
import Pagination from '../components/Pagination';

const features = [
  { name: "Facial", image: Facial, link: "/facial" },
  { name: "Waxing", image: Waxing, link: "/waxing" },
  { name: "Meni-Pedi", image: MeniPedi, link: "/meni-pedi" },
  { name: "Threading", image: Threading, link: "/threading" },
  { name: "Hair Service", image: HairService, link: "/hair-service" },
  { name: "Make Up", image: Makeup, link: "/makeup" },
  { name: "Nail Service", image: NailService, link: "/nail-service" },
];

const coreValues = [
  { title: 'Care', desc: 'We prioritize the well-being and satisfaction of our clients.' },
  { title: 'Quality', desc: 'We use top-tier products and follow the latest beauty trends.' },
  { title: 'Expertise', desc: 'Our team is made up of certified beauty experts.' },
  { title: 'Expertise', desc: 'Our team is made up of certified beauty experts.' },
];

const teamMembers = [
  { name: 'Lalita Nighot', role: 'Founder & Makeup Expert', img: 'https://scontent.fbom3-2.fna.fbcdn.net/v/t39.30808-1/452554941_1506839283247517_7819873232400345801_n.jpg?stp=c0.0.670.670a_dst-jpg_s200x200&_nc_cat=103&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=DcrWqrZdWK0Q7kNvgHAulcE&_nc_zt=24&_nc_ht=scontent.fbom3-2.fna&_nc_gid=AQ-Gejwn2E0FGbeYPO_e6--&oh=00_AYAZC8L43NHU3iFfmncX7n7zjc0pCkqDmR0sZ3HM33VX2g&oe=6737C8C5' },
  { name: 'Archana Thite', role: 'Nails Artist ', img: 'https://via.placeholder.com/150' },
];

// 9987574129 lilita
// 9594442838
const achievements = [
  { stat: '1000+', desc: 'Happy Clients' },
  { stat: '200+', desc: 'Bridal Makeovers' },
  { stat: '13+', desc: 'Years of Service' },
  { stat: '5+', desc: 'International Certifications' },
];

const images = [
  Lalita1,
  Lalita2,
  Lalita3,
  Lalita4,
  Lalita5,
  Lalita6,
  Lalita7,
  Lalita8,
  Lalita9,
  Lalita10,
  Lalita11,
  Lalita12
  // Add more image paths as needed
];

const Home = () => {
  return (
    <div className="home-container">


      {/* Main Content */}
      <main className="main-content">
        {/* Hero Section */}
        <section className="hero-section">
          <div className="hero-content">
            <img
              src='https://helibeauty.com/wp-content/uploads/2023/02/0-Header-2.jpg'
              alt="Hero"
              className="hero-image"
            />
            <br/>
            <div className="hero-text">
              <h2 className="hero-title">Welcome to SAAJ MAKE UP STUDIO and Training Institute</h2><span className='navbar-lakme'>(LAKME CERTIFIED)</span>
              <p className="hero-description">
                Professional Beauty Services only for ladies and home services available
              </p>
            </div>
          </div>
        </section>

        {/* Services Section */}
        <section className="features-section">
          <h3 className="features-title">Our Services</h3>
          <div className="features-grid">
            {features.map((feature, idx) => (
              <Link key={idx} to={feature.link} className="feature-card">
                <img src={feature.image} alt={feature.name} className="feature-image" />
                <h3 className="feature-title">{feature.name}</h3>
              </Link>
            ))}
          </div>
        </section>

        {/* Core Values Section */}
        <section className="core-values-section">
          <h3 className="core-values-title">Our Core Values</h3>
          <p className='core-values-desc'>To Enhance Your Natural Beauty With Our Scientific Treatment</p>
          <div className="core-values-grid">
            {coreValues.map((value, idx) => (
              <div key={idx} className="core-value-card">
                <h3 className="core-value-title">{value.title}</h3>
                <p className="core-value-description">{value.desc}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Team Section */}
        <section className="team-section">
          <h3 className="team-title">Meet Our Experts</h3>
          <div className="team-grid">
            {teamMembers.map((member, idx) => (
              <div key={idx} className="team-member-card">
                <img src={member.img} alt={member.name} className="team-member-img" />
                <h3 className="team-member-name">{member.name}</h3>
                <p className="team-member-role">{member.role}</p>
                <p className="team-member-bio">
                  {member.name.split(' ')[0]} specializes in {member.role.toLowerCase()}, ensuring each client leaves looking their best.
                </p>
              </div>
            ))}
          </div>
        </section>

        <section className="gallery-section">
        <h1 className="gallery-title">Image Gallery</h1>
        <Pagination items={images} itemsPerPage={1} />
        </section>

        {/* Achievements Section */}
        <section className="achievements-section">
          <h3 className="achievements-title">Our Achievements</h3>
          <div className="achievements-grid">
            {achievements.map((achievement, idx) => (
              <div key={idx} className="achievement-card">
                <h3 className="achievement-stat">{achievement.stat}</h3>
                <p className="achievement-desc">{achievement.desc}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Footer */}
        <Footer />
      </main>
    </div>
  );
};

export default Home;
