import React from 'react';
import '../styles/hairservice.css'; // Ensure to include the CSS file

const hairServices = [
  {
    name: "Haircut",
    description: "Professional haircut tailored to your style and preference.",
    price: "$25"
  },
  {
    name: "Hair Color",
    description: "Transform your look with our vibrant hair coloring services.",
    price: "$60"
  },
  {
    name: "Highlights",
    description: "Add dimension to your hair with stylish highlights.",
    price: "$80"
  },
  {
    name: "Hair Styling",
    description: "Get your hair styled for any occasion, from casual to formal.",
    price: "$35"
  },
  {
    name: "Hair Smoothing",
    description: "Smooth and straighten your hair with our keratin treatment.",
    price: "$120"
  },
  {
    name: "Updo",
    description: "Elegant updo styles for weddings and special events.",
    price: "$50"
  },
];

const HairService = () => {
  return (
    <div className="hairservice-container">
      <h1 className="hairservice-title">Hair Services</h1>
      <p className="hairservice-intro">
        Our hair services are designed to enhance your beauty and give you the perfect look for any occasion. 
        From haircuts to treatments, we've got you covered.
      </p>
      <div className="hairservice-services">
        {hairServices.map((service, idx) => (
          <div key={idx} className="hairservice-service-card">
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HairService;
