import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, collection, getDocs, deleteDoc, addDoc, updateDoc } from 'firebase/firestore';
//import { getAnalytics } from 'firebase/analytics';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCL1L3MA6qB1Uec7yFMFnMtRz28dwKzACY",
  authDomain: "parlour-app-bc604.firebaseapp.com",
  projectId: "parlour-app-bc604",
  storageBucket: "parlour-app-bc604.appspot.com",
  messagingSenderId: "310887704490",
  appId: "1:310887704490:web:7b18582e4943f9d656add3",
  measurementId: "G-8GED2XL67G"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Firestore
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// Send password reset email
const email = "tysonxwort@outlook.com";  // Replace with actual email
sendPasswordResetEmail(auth, email)
  .then(() => {
    console.log("Password reset email sent!");
  })
  .catch((error) => {
    console.error("Error sending password reset email:", error);
  });

export { auth, functions, firestore, storage, db, updateDoc, createUserWithEmailAndPassword, signInWithEmailAndPassword, doc, setDoc, getDoc, addDoc, collection, getDocs, ref, uploadBytes, deleteDoc, getDownloadURL };

